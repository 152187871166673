// import MenuIcon from '@mui/icons-material/MoreVert';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Assets/logo.svg';
import { Font, HStack, IconImage, Spacer, VStack } from '../../Component/Components';
import { logout } from '../../State/actions/auth';
import '../../StyleModule/main_style.css';
import './style.css';

function MenuBar(props) {
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ mobileMoreAnchorEl, setMobileMoreAnchorEl ] = useState(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    return () => {
    };
  }, [ props.auth ]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={ anchorEl }
      anchorOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      id={ menuId }
      keepMounted
      transformOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      open={ isMenuOpen }
      onClose={ handleMenuClose }
    >
      <MenuItem onClick={ handleMenuClose }>Profile</MenuItem>
      <MenuItem onClick={ handleMenuClose }>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={ mobileMoreAnchorEl }
      sx={ { top: -20, marginLeft: 2, zIndex: 10001 } }
      anchorOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      id={ mobileMenuId }
      keepMounted
      transformOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      open={ isMobileMenuOpen }
      onClose={ handleMobileMenuClose }
    >
      <Box sx={ { float: 'right', pr: 2 } }>
        <Font size='16px' color='red' style={ { cursor: 'pointer' } } onClick={ handleMobileMenuClose }><CloseIcon /></Font>
      </Box>
      { props?.auth.isAuthenticated ?
        <VStack justifycontent='end' gap='0px'>
          <MenuItem sx={ { height: 40 } }>
            <HStack onClick={ () => navigate("/search") }>
              <Font size='16px' style={ { cursor: 'pointer' } } weight='400'>GapFundr Profiles</Font>
            </HStack>
          </MenuItem>          

          { props?.auth.user.role === 0 ? 
          <MenuItem>
            <HStack onClick={ () => navigate("/admin/home") }>
              <Font size='16px' style={ { cursor: 'pointer' } } weight='400'>AdminDashboard</Font >
            </HStack>
          </MenuItem>
          :
          <MenuItem>
            <HStack onClick={ () => navigate("/dashboard/home") }>
              <Font size='16px' style={ { cursor: 'pointer' } } weight='400'>Dashboard</Font >
            </HStack>
          </MenuItem>
          }
          { props?.auth.user.role === 0 ? 
          <MenuItem>
            <HStack onClick={ () => navigate("/admin/campaigns") }>
              <Font size='16px' style={ { cursor: 'pointer' } } weight='400'>Campaigns</Font>
            </HStack>
          </MenuItem>
          :

          <MenuItem>
            <HStack onClick={ () => navigate("/dashboard/contribution") }>
              <Font size='16px' style={ { cursor: 'pointer' } } weight='400'>Contributions</Font>
            </HStack>
          </MenuItem>
          }
          { props?.auth.user.role === 0 ? 
          <MenuItem>
            <HStack onClick={ () => navigate("/admin/users") }>
              <Font size='16px' style={ { cursor: 'pointer' } } weight='400'>Users</Font>
            </HStack>
          </MenuItem>
          :

          <MenuItem>
            <HStack onClick={ () => navigate("/dashboard/setting") }>
              <Font size='16px' style={ { cursor: 'pointer' } } weight='400'>Settings</Font>
            </HStack>
          </MenuItem>
          }
          { props?.auth.user.role === 0 ? 
          <MenuItem>
            <HStack onClick={ () => navigate("/admindashboard/comments") }>
              <Font size='16px' style={ { cursor: 'pointer' } } weight='400'>Comments</Font>
            </HStack>
          </MenuItem>
          :
          <></>
          }

          <MenuItem>
            <HStack onClick={ props.logout }>
              <Font size='16px' weight='400' style={ { cursor: 'pointer' } } color='red'>Logout</Font>
            </HStack>
          </MenuItem>
        </VStack >
        :
        <VStack justifycontent='end' gap='0px'>
          <MenuItem>
            <HStack onClick={ () => navigate("/login") }>
              <Font size='16px' color='' weight='400' >Login/Sign Up</Font>
            </HStack>
          </MenuItem>
          <MenuItem>
            <HStack onClick={ () => navigate("/search") }>
              <Font size='16px' weight='400' style={ { cursor: 'pointer' } }>GapFundr Profiles</Font>
            </HStack>
          </MenuItem>
        </VStack >
      }
    </Menu >
  );

  return (
    <Box sx={ { flexGrow: 1, position: 'fixed', background: '#FFF', zIndex: 9999 } }
      style={ { width: '100%' } }>
      <AppBar position="static" sx={ { background: 'inherit', color: '#000' } } elevation={ 0 }>
        <Toolbar sx={ { padding: 1 } }>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={ { display: { xs: 'block', sm: 'block', } } }
          >
            <IconImage src={ Logo } weight='400' onClick={ () => navigate("/") } />
          </Typography>

          {/* <Spacer /> */ }
          <Box sx={ { flexGrow: 1, ml: 4, display: { xs: "none", md: "flex" } } } >
            <MenuItem className='button__search' sx={ { padding: '4px' } } onClick={ () => navigate('/search') }>
              <HStack gap='4px' justifycontent='space-between' alignitems='center'>
                <SearchIcon sx={ { color: '#C4C4C4;' } } />
                <Font size='14px'>
                  Search Student’s Profiles
                </Font>
                <Spacer />
                <HStack>
                  <ArrowRightAltIcon sx={ { color: '#DC4405' } } />
                </HStack>
              </HStack>
            </MenuItem>
          </Box>

          <Box sx={ { flexGrow: 1 } } />
          <Box sx={ { display: { xs: 'none', md: 'flex' } } }>
            { props?.auth.isAuthenticated ?
              <HStack gap='0px'>
                <MenuItem sx={ { borderRadius: 10 } }>
                  <HStack onClick={ () => navigate("/search") }>
                    <Font size='16px' weight='400' style={ { cursor: 'pointer' } }>GapFundr Profiles</Font>
                  </HStack>
                </MenuItem>
                {props?.auth.user.role === 0 ?
                <MenuItem sx={ { borderRadius: 10 } } >
                  <HStack onClick={ () => navigate("/admin/home") }>
                    <Font size='16px' weight='400' style={ { cursor: 'pointer' } }>Dashboard</Font>
                  </HStack>
                </MenuItem>
                :
                <MenuItem sx={ { borderRadius: 10 } } >
                  <HStack onClick={ () => navigate("/dashboard/home") }>
                    <Font size='16px' weight='400' style={ { cursor: 'pointer' } }>Dashboard</Font>
                  </HStack>
                </MenuItem>
                }
                
              </HStack>
              :
              <HStack alignitems='center'>
                <MenuItem sx={ { borderRadius: 10 } }>
                  <HStack onClick={ () => navigate("/search") } sx={ { borderRadius: 10 } }>
                    <Font size='16px' weight='400'>GapFundr Profiles</Font>
                  </HStack>
                </MenuItem>
                <MenuItem sx={ { borderRadius: 10, border: '2px solid #DC4405' } }>
                  <HStack onClick={ () => {
                    navigate("/login")
                  } }>
                    <Font size='16px' color='#DC4405' weight='400'>Login/Sign Up</Font>
                  </HStack>
                </MenuItem>
              </HStack>
            }
          </Box>

          <Box sx={ { display: { xs: 'flex', md: 'none' } } }>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={ mobileMenuId }
              aria-haspopup="true"
              onClick={ handleMobileMenuOpen }
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

        </Toolbar >
      </AppBar >
      { renderMobileMenu }
      { renderMenu }
    </Box >
  );
}

MenuBar.propTypes = {
  profile: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(mapStateToProps, { logout })(MenuBar);